import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import tw from "twin.macro"

import {
  TemplateProps,
  TenantPortalContentTemplatePageData,
} from "../templates"
import {
  TenantsContactsAndQuickPay,
  TenantsHeadline,
  TenantsResources,
} from "../../components/Tenants"
import { isLoggedIn } from "../../clients/Tenant"
import { SEO } from "../../components/SEO"
import { Image } from "../../components/Image"

const TenantPortal: React.FC = ({
  data,
  location,
}: TemplateProps<TenantPortalContentTemplatePageData>) => {
  const { title, content, featuredImage, template } = data.wpPage
  const { contactsSection, tenantResourcesSection } = template.tenantsPage

  const image = getImage(
    featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  )

  useEffect(() => {
    const authenticated = isLoggedIn()
    if (!authenticated) {
      navigate("/tenants/auth/")
    }
  }, [])

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        {isLoggedIn() && (
          <>
            <Image
              src={image}
              publicURL={featuredImage?.node?.localFile?.publicURL}
              alt=""
              role="presentation"
              css={[tw`w-full h-80`]}
              objectPosition="0% 50%"
            />
            <div
              css={[
                {
                  backgroundImage: "url('/airport-interior.png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                },
                tw`md:bg-cover`,
              ]}
            >
              <div css={[tw`mx-auto max-w-screen-lg`]}>
                <TenantsHeadline
                  wrapperStyles={[tw`md:mx-20`]}
                  title={title}
                  content={content}
                  line={false}
                />
                <TenantsContactsAndQuickPay contactsSection={contactsSection} />
              </div>
            </div>
            <TenantsResources
              wrapperStyles={[tw`mx-auto max-w-screen-lg`]}
              resourcesSection={tenantResourcesSection}
            />
          </>
        )}
      </section>
    </div>
  )
}

export default TenantPortal

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      template {
        templateName
        ... on WpTemplate_Tenants {
          templateName
          tenantsPage {
            fieldGroupName
            contactsSection {
              contacts
              fieldGroupName
              headline
              quickPayInformation {
                description
                title
              }
            }
            tenantResourcesSection {
              description
              fieldGroupName
              headline
              tenantResources {
                description
                fieldGroupName
                form
                title
                downloadFile {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
